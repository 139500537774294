import React, { useEffect } from 'react'
import { useFeatureFlags } from '@pff-consumer/feature-flags'
import Image from 'next/image'
import { useStyles } from './left-pane.styles'
import { PffInsights } from '../common/pff-insights/pff-insights'
import { useSubscribeAnalyticsEvent } from '../../hooks/useSubscribAnalyticsEvent'

const DEFAULT_HERO_IMAGE = '/assets/images/subscription-feature-hero.webp'

export const LeftPane = () => {
  const { getFeatureFlag } = useFeatureFlags()
  const heroImageSrc = getFeatureFlag<string>('mds-subscribe-app-hero-image', DEFAULT_HERO_IMAGE)
  const styles = useStyles()
  const { sendSubscribeAnalyticsEvent } = useSubscribeAnalyticsEvent()

  useEffect(() => {
    if (heroImageSrc !== DEFAULT_HERO_IMAGE) {
      sendSubscribeAnalyticsEvent('Subscribe Hero Image Viewed', {
        variant: heroImageSrc,
      })
    }
  }, [heroImageSrc, sendSubscribeAnalyticsEvent])

  return (
    <div className={styles.leftPaneContainer}>
      <div className={styles.gradientLayer} />
      <div className={styles.contentContainer}>
        <picture className={styles.foregroundImageContainer}>
          <Image
            src={heroImageSrc}
            className={styles.foregroundImage}
            alt='Checkout Benefits'
            fill
          />
        </picture>
        <div className={styles.insightsContainer}>
          <PffInsights />
        </div>
      </div>
    </div>
  )
}
