import React from 'react'
import { NextPageContext } from 'next'
import { deleteCookie, getCookies, setCookie } from 'cookies-next'
import * as Sentry from '@sentry/nextjs'
import { getIsUSUser } from '@pff-consumer/utils/lib/checkout/get-is-us-user'
import ErrorBoundary from '../components/error-boundary/error-boundary'
import { Layout } from '../components/layout/layout'
// import { MaintenanceMode } from '../components/maintenance-mode/maintenance-mode'
import { grootClient } from '../shared/constants'
import {
  getServerSidePropsForGoogleTagManager,
  GoogleTagManagerHead,
  GoogleTagManagerHeadProps,
} from '../utils/analytics/google'

interface IndexProps extends GoogleTagManagerHeadProps {
  email?: string
  isUSUser: boolean
}

function Index(props: IndexProps) {
  const { dataLayer } = props
  // const { getFeatureFlag } = useFeatureFlags()
  // const isMaintenanceModeEnabled = getFeatureFlag<boolean>('subscribe-enable-maintenance-mode', false)

  // if (isMaintenanceModeEnabled) {
  //   return <MaintenanceMode />
  // }
  return (
    <ErrorBoundary>
      <GoogleTagManagerHead dataLayer={dataLayer} />
      <Layout {...props} />
    </ErrorBoundary>
  )
}

const COOKIE_AGE = 60 * 60 * 24 * 7

// eslint-disable-next-line import/no-named-export
export async function getServerSideProps({ req, res }: NextPageContext) {
  const {
    c_groot_access_token: grootAccessToken,
    subscribe_access_token: subscribeAccessToken,
    subscribe_email: subscribeEmail,
    subscribe_groot_uid: subscribeGrootUid,
  } = getCookies({ req, res })

  const gtmProps = await getServerSidePropsForGoogleTagManager({ req, res } as NextPageContext)
  const isUSUser = await getIsUSUser(req)

  // In the simplest case, the user is already logged in and we have an email/UID
  // Here we'll pass those into the Index props
  if (subscribeEmail) {
    console.log('User found via email')
    return {
      props: {
        email: subscribeEmail,
        grootUid: subscribeGrootUid,
        ...gtmProps,
        isUSUser,
      },
    }
  }

  // If we have an access token but not yet retrieved the users information, then
  // we will use the access token to get the users information and then pass it along
  const accessToken = subscribeAccessToken || grootAccessToken
  if (accessToken) {
    try {
      console.log('Fetching user via an existing access token')

      // Get user information via the token
      const { uid, email, roles } = await grootClient.getGrootMe({ accessToken })

      // Check if the user is a subscriber, if so, redirect them back to PFF.com
      // Note: This is also in oauth.tsx, needs refactored into a shared subscription module
      if (roles.includes('consumer-premium')) {
        return {
          redirect: {
            destination: process.env.PFF_DOMAIN,
            permanent: false,
          },
        }
      }

      // Set cookies that the subscribe app can use
      setCookie('subscribe_access_token', accessToken, { req, res, maxAge: COOKIE_AGE })
      setCookie('subscribe_groot_uid', uid, { req, res, maxAge: COOKIE_AGE })
      setCookie('subscribe_email', email, { req, res, maxAge: COOKIE_AGE })

      return {
        props: {
          email,
          grootUid: uid,
          ...gtmProps,
        },
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      deleteCookie('subscribe_access_token', { req, res })
      // fallback to the return with non-logged in props below
    }
  }

  // If we didn't have any Groot/OAuth information, then we'll assume the user is not logged in
  // and probably doesn't have an account. We'll proceed without sending any props.
  return {
    props: { ...gtmProps, isUSUser },
  }
}

export default Index
