import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface BannerStyles {
  container: Styles
}

export const useStyles = createUseStyles<BannerStyles>((theme): BannerStyles => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#0055EE',
      padding: theme.calcUnit(theme.spacing.s4),
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.calcUnit(theme.spacing.s4),
    },
  }
})
