import React, { useEffect, useMemo } from 'react'
import { SubscriptionPlans } from '@pff-consumer/schema'
import { useFeatureFlags } from '@pff-consumer/feature-flags'
import dynamic from 'next/dynamic'
import { checkoutHooks } from '@pff-consumer/data-provider/lib/checkout/checkout.hooks'
import { LeftPane } from '../left-pane/left-pane'
import { Header } from '../common/header/header'
import { useStyles } from './layout.style'
import { RecurlyContainerType } from '../recurly-container/recurly-container'
import { Banner } from '../common/banner/banner'
import { LayoutProps } from './layout.props'

const RecurlyPaymentSection = dynamic<React.ComponentProps<RecurlyContainerType>>(
  () => import('../recurly-container/recurly-container').then((res) => res.RecurlyContainer),
  {
    ssr: false,
  }
)

const { useCheckoutActions } = checkoutHooks

export const Layout = ({ email, grootUid, isUSUser }: LayoutProps) => {
  const { setDefaultEmail, setGrootUid, updateMonthlyPlan, updateVariant, setNewLetterPreference } =
    useCheckoutActions()
  const styles = useStyles()
  const { getFeatureFlag } = useFeatureFlags()
  const showBanner = getFeatureFlag<boolean>('subscribe-upsell-banner', true)

  // TODO update feature flags to be SSR in so we don't have to update the fallback
  const monthlyPlan = getFeatureFlag<string>(
    'subscribe-monthly-variant',
    SubscriptionPlans.PREMIUM_MONTHLY_SEASON
  ) as SubscriptionPlans

  useEffect(() => {
    updateMonthlyPlan(monthlyPlan)
  }, [monthlyPlan])

  useEffect(() => {
    updateVariant(monthlyPlan)
  }, [updateVariant, monthlyPlan])

  useEffect(() => {
    setDefaultEmail(email)
    setNewLetterPreference(isUSUser)
    if (grootUid) {
      setGrootUid(grootUid)
    }
    // we only want to run this once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * The layout needs to turn SSR off for this component because recurly does not support SSR. This means we need to memoize the payment form to prevent this re-rendering and re-fetching recurly scripts over again
   */
  const PaymentForm = useMemo(() => <RecurlyPaymentSection />, [])

  return (
    <div className={styles.container}>
      <div className={styles.leftPane}>
        <LeftPane />
      </div>
      <div
        id='desktopFormScrollContainer' // added to set scrolling to top and bottom
        className={styles.rightPane}
      >
        <div className={styles.mobileOnly}>
          <Header />
        </div>
        {showBanner && <Banner />}
        {PaymentForm}
      </div>
    </div>
  )
}
