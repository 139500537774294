import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface LayoutStyles {
  container: Styles
  leftPane: Styles
  rightPane: Styles
  mobileOnly: Styles
}

export const useStyles = createUseStyles<LayoutStyles>((theme) => {
  return {
    container: {
      display: 'flex',
    },
    leftPane: {
      width: '50%',
      height: '100dvh',
      ...theme.breakpoint.down<Styles>('md', {
        display: 'none',
      }),
    },
    rightPane: {
      width: '50%',
      height: '100dvh',
      overflowY: 'auto',
      ...theme.breakpoint.down<Styles>('md', {
        width: '100%',
      }),
    },
    mobileOnly: {
      display: 'none',
      ...theme.breakpoint.down<Styles>('md', {
        display: 'block',
      }),
    },
  }
})
