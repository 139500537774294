// import { Icon } from '@pff-consumer/tweed-ui/atoms/icon'
// import { SVGGroup } from '@pff-consumer/utils'
import { Text, TextDescriptionVariant } from '@pff-consumer/web-ui/components/browser-text/browser-text'
import { useFeatureFlags } from '@pff-consumer/feature-flags'
import { colors } from '@pff-consumer/core-ui'
import { useStyles } from './banner.styles'

export const Banner = () => {
  const styles = useStyles()
  const { getFeatureFlag } = useFeatureFlags()
  const bannerText = getFeatureFlag<string>('subscribe-upsell-banner-text', 'Subscribe Today')

  return (
    <div className={styles.container}>
      {/* <Icon
        name='pff-plus-white'
        testID='pff-logo'
        group={SVGGroup.Icons}
        height={18}
        width={50}
        className=''
      /> */}
      <Text
        variant={TextDescriptionVariant.REGULAR_SECONDARY_SEMIBOLD}
        colorOverride={colors.textColor}
      >
        {bannerText}
      </Text>
    </div>
  )
}
