import React, { memo } from 'react'
import { Icon } from '@pff-consumer/tweed-ui/atoms/icon/icon.web'
import { SVGGroup } from '@pff-consumer/utils'
import { useStyles } from './header.styles'

export const Header = memo(() => {
  const styles = useStyles()

  return (
    <header
      className={styles.header}
      id='header'
    >
      <Icon
        name='pff-logo'
        testID='pff-logo'
        group={SVGGroup.Icons}
        height={16}
        className={styles.logo}
      />
    </header>
  )
})
